import React from 'react';
import { useInView } from 'react-intersection-observer';
import ym from 'react-yandex-metrika';
import { useLocation } from "@reach/router";
import * as PropTypes from "prop-types";
import { Formik, Field, Form }  from "formik";
import * as yup from 'yup';
import axios from 'axios';
import Modal from "react-modal";
import Interpolate from 'react-interpolate-component';

import Button from "../../components/Button";
import useLanguage from "../../hooks/useLanguage";
import '../../styles/form/forms/callBackForm.scss'
import iconClose from "../../images/icons/buttons/close.svg";
import iconCloseMobile from "../../images/icons/buttons/close-mobile.svg";
import iconClip from "../../images/icons/form/clip.svg";
import iconCheck from "../../images/icons/form/check.svg";
import iconLoading from "../../images/icons/form/loading.svg";
import iconRepeat from "../../images/icons/form/repeat.svg";
import iconFloodedError from "../../images/icons/form/floodedError.svg";
import iconFloodedSuccess from "../../images/icons/form/floodedSuccess.svg";
import { createInternalLink } from '../../utils/createInternalLink';

import enMessages from '../../i18n/signUpForm/en.json'
import ruMessages from '../../i18n/signUpForm/ru.json'
import LinkButton from '../../components/ContactBank/LinkButton';

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const FORM_FIELD = {
  comment: '',
  confirmation: false,
  contactName: '',
  contactSurname: '',
  contactPatronymic: '',
  email: '',
  files: '',
  phone: '',
};

const FORM_SCREEN_DEFAULT = 'fosForm';
const FORM_TEXTAREA_MAXLENGTH = 256;
const FILE_STATUSES = {
  default: 'default',
  error: 'error',
  finish: 'finish',
  process: 'process',
  success: 'success',
}
const MAX_COUNT_FILES = 10;
const UPLOADED_FILES = []

const SignUpFosForm = ({ openFormLinkTitle, canBeFixed = "false" }) => {
  Modal.setAppElement("#___gatsby");

  const [language] = useLanguage();

  const { href, state } = useLocation();

  const signUpFosFormOpened = state?.signUpFosFormOpened || false;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const inputFileRef = React.useRef(null);

  const [modalIsOpen, setIsOpen] = React.useState(signUpFosFormOpened);

  const [fosData, setFosData] = React.useState({
      screen: FORM_SCREEN_DEFAULT,
  });

  const [initialValues, setInitialValues] = React.useState(FORM_FIELD);

  const [fileAddingStatus, setFileAddingStatus] = React.useState(FILE_STATUSES.default);
  const [fileErrorInfo, setFileErrorInfo] = React.useState('');
  const [uploadedFiles, setUploadedFiles] = React.useState(UPLOADED_FILES);

  const fileBtnData = React.useMemo(
    () => {
      switch (fileAddingStatus) {
        case FILE_STATUSES.finish:
          return {
            img: {
              alt: 'Replace file',
              src: iconRepeat,
            },
            title: messages[language].userReplaceFile,
          };

        case FILE_STATUSES.process:
          return {
            img: {
              alt: 'Uploading a file',
              src: iconLoading,
            },
            title: messages[language].userLoading,
          };

        case FILE_STATUSES.success:
          return {
            img: {
              alt: 'File downloaded',
              src: iconCheck,
            },
            title: messages[language].userLoadingFileSuccess
          };

        default:
          return {
            img: {
              alt: 'Attach file',
              src: iconClip,
            },
            title: messages[language].userFile,
          };
      }
    },
    [fileAddingStatus],
  );

  const handleFileBtnClick = React.useCallback(
    () => inputFileRef?.current && inputFileRef.current.click(),
    [inputFileRef.current],
  );

  const resetFormState = React.useCallback(
    () => {
      if (fileAddingStatus !== FILE_STATUSES.default) {
        setFileAddingStatus(FILE_STATUSES.default);
      }

      if (uploadedFiles.length) {
        setUploadedFiles(UPLOADED_FILES);
      }

      setInitialValues(FORM_FIELD);
    },
    [
      fileAddingStatus,
      setFileAddingStatus,
      setInitialValues,
      setUploadedFiles,
      uploadedFiles,
    ],
  );

  const handleSuccessButtonClick = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen, setFosData,resetFormState]);

  const submitFOSData = (formData) => {
    setFosData({screen: 'process'});

    const sentData = new FormData();

    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        sentData.append(key, formData[key]);
      }
    });

    if (uploadedFiles.length) {
      uploadedFiles.forEach(file => sentData.append("files[]", file, file.name));
    }

    sentData.append("source", href);

    axios({
      method: 'post',
      url: '/scripts/application/createFos',
      headers: { 'content-type': 'multipart/form-data' },
      data: sentData,
    })
      .then((response) => {
        if (response.data.status === 'success') {
          setFosData({screen: 'success'});
          ym('reachGoal', 'fos_v4_send', { params: { url: href } });
        } else {
          setFosData({screen: 'error'});
        }
      })
      .catch(() => {
        setFosData({screen: 'error'});
      });
  };

  const validationSchema = yup.object({
    contactName: yup
      .string(messages[language].form_validation_name)
      .min(2, messages[language].form_validation_incorrect_name)
      .required(messages[language].form_validation_name),
    contactPatronymic: yup
      .string(messages[language].form_validation_incorrect_patronymic)
      .min(2, messages[language].form_validation_incorrect_patronymic),
    contactSurname: yup
      .string(messages[language].form_validation_surname)
      .min(2, messages[language].form_validation_incorrect_surname)
      .required(messages[language].form_validation_surname),
    comment: yup
      .string(messages[language].form_validation_comment)
      .min(5, messages[language].form_validation_incorrect_comment)
      .required(messages[language].form_validation_comment),
    email: yup
      .string(messages[language].form_validation_mail)
      .email(messages[language].form_validaton_incorrect_mail)
      .required(messages[language].form_validation_mail),
    phone: yup
      .string(messages[language].form_validation_phone)
      .matches(/^((\+7|8)+([0-9]){10})$/, messages[language].form_validation_phone_correct)
      .required(messages[language].form_validation_phone),
  });

  const ScreenFosForm = () => (
    <Formik
      initialStatus="default"
      initialValues={initialValues}
      onSubmit={(values) => submitFOSData(values)}
      validationSchema={validationSchema}
    >
      {({ errors, values, touched }) => (
        <div>
          <Form className="FieldFormBase" >
            <Field
              className="callBackForm-inputText"
              name="contactSurname"
              placeholder={`${messages[language].userSurname}*`}
              type="text"
            />

            {errors.contactSurname && touched.contactSurname
              ? <div className="callBackForm-ValidationError">{errors.contactSurname}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="contactName"
              placeholder={`${messages[language].userName}*`}
              type="text"
            />

            {errors.contactName && touched.contactName
              ? <div className="callBackForm-ValidationError">{errors.contactName}</div>
              : null}

            {language === 'ru' && <>
              <Field
                className="callBackForm-inputText"
                name="contactPatronymic"
                placeholder={messages[language].userPatronymic}
                type="text"
              />

              {errors.contactPatronymic && touched.contactPatronymic
                ? <div className="callBackForm-ValidationError">{errors.contactPatronymic}</div>
                : null}
            </>}

            <Field
              className="callBackForm-inputText"
              name="email"
              placeholder={`${messages[language].userMail}*`}
              type="text"
            />

            {errors.email && touched.email
              ? <div className="callBackForm-ValidationError">{errors.email}</div>
              : null}

            <Field
              type="text"
              placeholder={`${messages[language].companyphone}*`}
              name="phone"
              className="callBackForm-inputText"
              maxLength={12}
            />

            {errors.phone && touched.phone
              ? <div className="callBackForm-ValidationError">{errors.phone}</div>
              : null}

            <label
              className="textareaLabel"
              htmlFor="userComment"
            >
              {messages[language].userComment}

              <div className="textareaCounter">
                {values.comment.length}/{FORM_TEXTAREA_MAXLENGTH}
              </div>
            </label>

            <Field
              as="textarea"
              className="callBackForm-inputText callBackForm-inputTextArea"
              id="userComment"
              maxLength={FORM_TEXTAREA_MAXLENGTH}
              name="comment"
              placeholder={`${messages[language].userComment}*`}
              type="text"
            />

            {errors.comment && touched.comment
              ? (
                <div className="callBackForm-ValidationError callBackForm-inputTextAreaValidationError">
                  {errors.comment}
                </div>
              )
              : null}

            <label
              className="callBackForm-labelFile"
              htmlFor="userFile"
            >
              <button
                onClick={handleFileBtnClick}
                type="button"
                className={
                  `callBackForm-labelFileBtn ${fileAddingStatus === FILE_STATUSES.success
                    ? 'callBackForm-labelFileBtn-Success'
                    : fileAddingStatus === FILE_STATUSES.finish
                    ? 'callBackForm-labelFileBtn-Repeat'
                    : ''}`}
              >
                <img
                  alt={fileBtnData.img.alt}
                  className={`callBackForm-labelFileImg ${ fileAddingStatus === FILE_STATUSES.process ? 'callBackForm-labelFileLoadingImg' : ''}`}
                  src={fileBtnData.img.src}
                />

                {fileBtnData.title}
              </button>

              {fileAddingStatus === FILE_STATUSES.error &&
                <p className="callBackForm-labelFileErrorText">
                  {messages[language].userFiletryAgain}{fileErrorInfo && `. ${fileErrorInfo}`}
                </p>
              }

              {uploadedFiles && (
                <p className="callBackForm-labelAddFileNames">
                  {uploadedFiles.map((file) => file.name).join(', ')}
                </p>
              )}
            </label>

            <Field name="files">
              {({ field, meta }) => (
                <>
                  <input
                    {...field}
                    ref={inputFileRef}
                    id="userFile"
                    type="file"
                    multiple='multiple'
                    accept=".pdf, .png, .jpg, .jpeg"
                    onChange={(event) => {
                      setFileErrorInfo('');
                      setInitialValues({...values});
                      setFileAddingStatus(FILE_STATUSES.process);
                      setUploadedFiles(UPLOADED_FILES);

                      const files = event?.currentTarget?.files;
                      const readFiles = [].map.call(files, file => {
                        const fileReader = new FileReader();

                        return new Promise((resolve, reject) => {
                          fileReader.onload = () => {
                            resolve(file);
                          }

                          fileReader.onerror = () => reject('Ошибка чтения файла!');

                          if (/pdf|png|jpg|jpeg/.test(file.type)) {
                            fileReader.readAsDataURL(file)
                          } else {
                            reject('Можно загружать только pdf|png|jpg|jpeg!')
                          }
                        });
                      });

                      Promise.all(readFiles)
                        .then((files) => {
                          if (files?.length > MAX_COUNT_FILES) {
                            setFileAddingStatus(FILE_STATUSES.error);
                            setFileErrorInfo(messages[language].form_validation_max_files);

                            return null;
                          }

                          setUploadedFiles([...files]);
                          setFileAddingStatus(FILE_STATUSES.success);

                          const successBtnTimeout = setTimeout(
                            () => {
                              setFileAddingStatus(FILE_STATUSES.finish);

                              return clearTimeout(successBtnTimeout);
                            },
                            1000,
                          );
                        })
                        .catch(() => {
                          setFileAddingStatus(FILE_STATUSES.error);
                        });
                        if (!event) {
                          field.onChange(event);
                        }
                    }}
                  />

                  {meta.touched && meta.error && (
                    <div className="callBackForm-ValidationError">{meta.error}</div>
                  )}
                </>
              )}
            </Field>

            <div className="callBackForm-agreement formBlockAgreementFos">
              <label>
                <Field type="checkbox" name="confirmation"/>

                  <Interpolate
                    with={{
                      linkToConsentProcessingPD: (
                        <a
                          href={createInternalLink(language, '/consent-processing-pd/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationConsentProcessingPD}
                        </a>
                      ),
                      linkToPrivacyPolicy: (
                        <a
                          href={createInternalLink(language, '/dengiru/privacy-policy/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationPrivacyPolicy}
                        </a>
                      ),
                    }}
                  >
                    {messages[language].confirmationLabel}
                  </Interpolate>
              </label>
            </div>

            <Button
              disabled={!values.confirmation}
              type="submit"
            >
              {messages[language].submit}
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );

  const ScreenSuccess = () => (
    <div className="callBackForm-BlockMessage">
      <div>
        <img src={iconFloodedSuccess} alt="icon" />
      </div>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_fos_success}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button type="submit" click={handleSuccessButtonClick}>
          {messages[language].form_result_fos_success_button}
        </Button>
      </div>
    </div>
  );

  const ScreenError = () => (
    <div className="callBackForm-BlockMessage">
      <div>
        <img src={iconFloodedError} alt="icon" />
      </div>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_fos_error}
      </div>

      <div class="callBackForm-BlockMessage-desc">
        {messages[language].form_result_error_desc}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button type="submit" click={handleSuccessButtonClick}>
          {messages[language].form_result_fos_error_button}
        </Button>
      </div>
    </div>
  );

  const ScreenProcess = () => (
    <div className="callBackForm-BlockMessage callBackForm-BlockMessageProcess">
      <div className="callBackForm-BlockMessage-text">
        {messages[language].form_result_process}
      </div>
    </div>
  );

  const onOpen = React.useCallback(() => {
    setFosData({ screen: FORM_SCREEN_DEFAULT });
    resetFormState();
    setIsOpen(true);
  }, [resetFormState, setIsOpen]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    resetFormState();
  }, [resetFormState, setIsOpen])

  return (
    <div ref={ref}>
      <div className={`button-fos-wrapper ${canBeFixed === "true" ? "button-fos-wrapper-canfixed" : ""} ${inView === false ? "fixedBtn": ""}`}>
        <LinkButton
          className="ContactBank-LinkButtonBtnContact"
          onClick={onOpen}
          title={openFormLinkTitle}
        />
      </div>

      <Modal
        className="callBackForm"
        closeTimeoutMS={100}
        isOpen={modalIsOpen}
        onRequestClose={onClose}
        overlayClassName="callBackForm-Overlay"
        portalClassName="FOS-callBackForm"
      >
        {fosData.screen === 'fosForm' && <ScreenFosForm />}
        {fosData.screen === 'success' && <ScreenSuccess />}
        {fosData.screen === 'error' && <ScreenError />}
        {fosData.screen === 'process' && <ScreenProcess />}

        <a onClick={onClose}>
          <img src={iconClose} className="callBackForm-iconClose" />
          <img src={iconCloseMobile} className="callBackForm-iconCloseMobile" />
        </a>
      </Modal>
    </div>
  );
};

SignUpFosForm.propTypes = {
  fosDataStart: PropTypes.object,
  fosDataUser: PropTypes.object,
  openFormLinkTitle: PropTypes.string,
};

export default SignUpFosForm;
