import enMessages from '../../../i18n/form/en.js';
import ruMessages from '../../../i18n/form/ru.js';

export const MESSAGES = {
  en: enMessages,
  ru: ruMessages,
};

export const FORM_FIELDS = {
  companyName:'',
  companyIdentificationNumber: '',
  comment: '',
  confirmation: false,
  contactName: '',
  contactSurname: '',
  contactPatronymic: '',
  email: '',
  files: '',
  phone: '',
};

export const FORM_SCREENS = {
  error: 'error',
  form: 'form',
  success: 'success',
  process: 'process',
};

export const FORM_SCREEN_DEFAULT = FORM_SCREENS.form;

export const SCREEN_DATA = {
  screen: FORM_SCREEN_DEFAULT,
  title: '',
  description: '',
}
