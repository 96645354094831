import React from 'react';

import iconEmail from '../../../images/icons/contacts/email.svg';
import SignUpFosForm from "../../../forms/instances/SignUpFosForm";
import SignUpCompanyForm from '../../../forms/instances/SignUpCompanyForm/SignUpCompanyForm';

export const ACTIONS_FORMS = {
  company: 'openFeedbackCompanyForm',
  user: 'openFeedbackUserForm'
};

export const Contact = (props) => {
  const { contact } = props;

  const renderForm = (actionForm) => {
    switch (actionForm) {
      case ACTIONS_FORMS.company: 
          return <SignUpCompanyForm openFormLinkTitle={contact.text} />;
      case ACTIONS_FORMS.user: 
          return <SignUpFosForm openFormLinkTitle={contact.text} />;
      default:
        return null;
    }
  };

  return (
    <div className="ContactBank-Contact">
      <div className="ContactBank-ContactContainer">
        <div className="ContactBank-ContactTitle">
          <div className="ContactBank-ContactTitleIconBox">
            <img
              alt="Contact name"
              className="ContactBank-ContactTitleIcon"
              src={contact.icon}
            />
          </div>

          <div className="ContactBank-ContactTitleText" dangerouslySetInnerHTML={{__html: contact.name}} />
        </div>

        {contact.link ? (
          <div className="ContactBank-ContactContent">
            <div className="ContactBank-ContactContentBox">
              <img className="ContactBank-ContactContactIcon"
                src={iconEmail}
                alt="Bank contact"
              />
              {contact.text}
            </div>
          </div>
        ) : null}

        {contact.action ? (
          <div className="ContactBank-ContactContent">
              {renderForm(contact.action)}
          </div>
        ) : null}
      </div>

      {contact?.info ? (
        <div className="ContactBank-ContactInfo">{contact.info}</div>
      ) : null}
    </div>
  );
};
